import React, { Suspense, useEffect, useMemo } from 'react'
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material"
import { Toaster } from 'react-hot-toast';

import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Route, Routes, useLocation, useNavigation } from "react-router";
import { colors } from '../utils/colors';
import { User } from "../models/user";
import { scrollToTop } from '../utils/app-helper';


import Layout from "../layout/layout"
import ThemeSnackbar from "../components/theme-snackbar"
import ProtectedRouteAuthCheck from "./protected-route-auth-check"
import AuthLayout from "../auth/auth-layout/auth-layout"
import NoPageFound from "../pages/no-page-found"
import LoadingView from '../components/loading-view'
import OperatorLayout from '../operator-layout'

const Dashboard = React.lazy(() => import("../pages/dashboard/dashboard"))
const MyAccount = React.lazy(() => import('../pages/my-account'))
const Operators = React.lazy(() => import('../pages/fleet-managment/operators'))
const Assets = React.lazy(() => import('../pages/fleet-managment/assets'))
const Batteries = React.lazy(() => import('../pages/fleet-managment/battery'))
const Vehicles = React.lazy(() => import('../pages/fleet-managment/vehicles'))
const SingleOperator = React.lazy(() => import('../pages/fleet-managment/operators/SingleOperator'))
const Farmers = React.lazy(() => import('../pages/farmers'))
const CreateFarmer = React.lazy(() => import('../pages/farmers/create-farmer'))
const CreateOperator = React.lazy(() => import('../pages/fleet-managment/operators/createOperator'))
const CreateService = React.lazy(() => import('../pages/services/create-service'))
const ServiceList = React.lazy(() => import('../pages/services/service-list'))
const Users = React.lazy(() => import('../pages/users'))
const ViewFarmer = React.lazy(() => import('../pages/farmers/viewFarmer'))
const Clients = React.lazy(() => import('../pages/clients'))
const CreateClient = React.lazy(() => import('../pages/clients/create-client'))
const UpdateClient = React.lazy(() => import('../pages/clients/update-client'))
const BaseClientList = React.lazy(() => import('../pages/clients/base-client/base-client-list'))
const CropManagement = React.lazy(() => import('../pages/crop-management'))
const Schemes = React.lazy(() => import('../pages/scheme/schemes'))
const CreateScheme = React.lazy(() => import('../pages/scheme/create-scheme'))
const OperatorDashboard = React.lazy(() => import('../pages/asset-operator/operator-dashboard'))
const OperatorServiceList = React.lazy(() => import('../pages/asset-operator/service-list/operator-service-list'))
const OperatorAccount = React.lazy(() => import('../pages/asset-operator/operator-account/operator-account'))
const ServiceDetail = React.lazy(() => import('../pages/asset-operator/service-list/service-detail'))
const TerList = React.lazy(() => import('../pages/hr/ter/ter-list'))
const UserAttendance = React.lazy(() => import('../pages/hr/attendance'))
const Training = React.lazy(() => import('../pages/asset-operator/training'))
const ServiceReport = React.lazy(() => import('../pages/reports/service-report'))
const FarmerReport = React.lazy(() => import('../pages/reports/farmer-report'))
const FarmReport = React.lazy(() => import('../pages/reports/farm-report'))
const AssetReport = React.lazy(() => import('../pages/reports/asset-report'))
const CropReport = React.lazy(() => import('../pages/reports/crop-report'))
const OperatorReport = React.lazy(() => import('../pages/reports/operators-report'))
const BatteryReport = React.lazy(() => import('../pages/reports/battery-report'))
const VehicleReport = React.lazy(() => import('../pages/reports/vehicle-report'))
const RegionalClientsReport = React.lazy(() => import('../pages/reports/regional-clients-report'))
const SchemeReport = React.lazy(() => import('../pages/reports/scheme-report'))
const OperatorUpdateFarmer = React.lazy(() => import('../pages/asset-operator/update-farmer'))
const Providers = React.lazy(() => import('../pages/providers'))
const Cluster = React.lazy(() => import('../pages/cluster/cluster'))
const UpdateService = React.lazy(() => import('../pages/services/update-service'))
const Transactions = React.lazy(() => import('../pages/transactions'))
const ClientServiceReport = React.lazy(() => import('../pages/reports/client-service-report'))
const Activity = React.lazy(() => import('../pages/asset-operator/activity'))
const CreateMeeting = React.lazy(() => import('../pages/asset-operator/activity/meetings/create'))
const Meeting = React.lazy(() => import('../pages/asset-operator/activity/meetings'))
const Meetings = React.lazy(() => import('../pages/asset-operator/activity/meetings/list'))
const MeetingReport = React.lazy(() => import('../pages/reports/meeting-report'))
const FarmerRegistration = React.lazy(() => import('../pages/campaign/farmer-registration'))
const AllTransactionsReport = React.lazy(() => import('../pages/reports/transaction-report'))
const AssetSprayReport = React.lazy(() => import('../pages/reports/asset-spray-report'))
const Dges = React.lazy(() => import('../pages/dge'))
const CreateDge = React.lazy(() => import('../pages/dge/createDge'))
const UpdateDge = React.lazy(() => import('../pages/dge/updateDge'))
const MyAssociates = React.lazy(() => import('../pages/my-associates'))
const SingleUserAttendance = React.lazy(() => import('../pages/hr/attendance/singleUserAttendance'))
const MyTeam = React.lazy(() => import('../pages/asset-operator/my-team'))
const CashDeposit = React.lazy(() => import('../pages/asset-operator/cash-deposit'))
const CahsDepositList = React.lazy(() => import('../pages/asset-operator/cash-deposit/cash-deposit-list'))
const CashDepositEntries = React.lazy(() => import('../pages/cash-deposit-entries'))
const CashTransactionsReport = React.lazy(() => import('../pages/reports/cash-transaction-report'))
const OrderTracking = React.lazy(() => import('../pages/order-tracking'))
const ComingSoon = React.lazy(() => import('../pages/coming-soon'))
const DeclinedServices = React.lazy(() => import('../pages/services/service-list/declined-services'))
const Trainings = React.lazy(() => import("../pages/trainings"))
const CreateTraining = React.lazy(() => import("../pages/trainings/create-training"))
const MyTrainings = React.lazy(() => import('../pages/trainings/my-trainings'))
const DroneDetails = React.lazy(() => import('../pages/fleet-managment/assets/drone-details'))
const UserTrainingsReport = React.lazy(() => import('../pages/reports/user-trainings-report'))


export default function WebRoutes() {

    const location = useLocation()
    const currentLocation = location.pathname

    const initialMode = useSelector((state: RootState) => state.colorMode.mode)
    const errorMessage = useSelector((state: RootState) => state.snackbarMessage.message)
    const user = useSelector((state: RootState) => state.userAuth)
    const currentUser: User = user?.currentUser

    const superAdmin = currentUser?.role?.name == 'super admin'
    const admin = currentUser?.role?.name == 'admin' || superAdmin
    const rm = currentUser?.role?.name == 'rm'
    const rtl = currentUser?.role?.name == 'rtl'

    const isMobile = useMediaQuery('(max-width:767px)')

    const theme = useMemo(() =>
        createTheme({
            palette: {
                primary: {
                    main: colors.primary, //'#0C9463',
                },
                secondary: {
                    main: colors.secondary, // '#007944',
                },
                success: {
                    main: colors.success,
                },
                warning: {
                    main: colors.warning,
                },
                error: {
                    main: colors.error,
                },

                mode: initialMode,
                ...(initialMode != 'light'
                    && {
                    background: {
                        default: '#000',
                        paper: '#000'
                    }
                }),
            },
            typography: {
                h1: { fontFamily: "Montserrat, sans-serif" },
                h2: { fontFamily: "Montserrat, sans-serif" },
                h3: { fontFamily: "Montserrat, sans-serif" },
                h4: { fontFamily: "Montserrat, sans-serif" },
                h5: { fontFamily: "Montserrat, sans-serif" },
                h6: { fontFamily: "Montserrat, sans-serif" },
                button: {
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 500,
                },
                fontFamily: "Nunito, sans-serif",
                // fontFamily: "Roboto, sans-serif"
            },
            components: {
                MuiButton: {
                    styleOverrides: {
                        root: {
                            borderRadius: '50vh'
                        }
                    }
                },
                MuiMenu: {
                    styleOverrides: {
                        paper: {
                            borderRadius: '16px !important'
                        },
                    },
                },
                MuiTooltip: {
                    styleOverrides: {
                        tooltip: {
                            padding: '6px 14px',
                            borderRadius: '10px',
                        }
                    }
                }
            },
        }),
        [initialMode],
    )

    useEffect(() => {
        scrollToTop()
    }, [currentLocation])


    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Routes>
                <Route path="auth" element={<AuthLayout />} />
                <Route path="*" element={<NoPageFound />} />
                <Route element={<ProtectedRouteAuthCheck />}>
                    {currentUser?.role?.name == 'operator' || currentUser?.role?.name == 'dge'
                        ? <Route path='/' element={<OperatorLayout />}>
                            <Route path=''
                                element={<Suspense fallback={<LoadingView />}><OperatorDashboard /></Suspense>} />
                            {/* <Route path='ter' element={<Suspense fallback={<LoadingView />}><TerDashboard /></Suspense>} />
                            <Route path='ter/claim' element={<Suspense fallback={<LoadingView />}><ClaimTer /></Suspense>} />
                            <Route path='ter/list/:categoryId' element={<Suspense fallback={<LoadingView />}><Ter />} /></Suspense> */}
                            {/* <Route path='ter/detail' element={<Suspense fallback={<LoadingView />}><UpdateTer /></Suspense>} />
                            <Route path='ter/create' element={<Suspense fallback={<LoadingView />}><CreateTer />} /></Suspense> */}
                            <Route path='account'
                                element={<Suspense fallback={<LoadingView />}><OperatorAccount /></Suspense>} />
                            <Route path='training'
                                element={<Suspense fallback={<LoadingView />}><Training /></Suspense>} />

                            <Route path='activity'
                                element={<Suspense fallback={<LoadingView />}><Activity /></Suspense>} />
                            <Route path='activity/meeting'
                                element={<Suspense fallback={<LoadingView />}><Meeting /></Suspense>} />
                            <Route path='activity/meeting/list'
                                element={<Suspense fallback={<LoadingView />}><Meetings /></Suspense>} />
                            <Route path='activity/meeting/create'
                                element={<Suspense fallback={<LoadingView />}><CreateMeeting /></Suspense>} />

                            {(currentUser?.is_supervisor_head == '1') &&
                                <>
                                    <Route path="attendance"
                                        element={<Suspense fallback={<LoadingView />}><UserAttendance /></Suspense>} />
                                    <Route path="my-team"
                                        element={<Suspense fallback={<LoadingView />}><MyTeam /></Suspense>} />
                                    <Route path="attendance/:userId?/:month?" element={<Suspense
                                        fallback={<LoadingView />}><SingleUserAttendance /></Suspense>} />
                                </>
                            }

                            {currentUser?.role?.name == 'operator' &&
                                <>
                                    <Route path='services' element={<Suspense
                                        fallback={<LoadingView />}><OperatorServiceList /></Suspense>} />
                                    <Route path="services/:serviceId"
                                        element={<Suspense fallback={<LoadingView />}><ServiceDetail /></Suspense>} />
                                    <Route path='operator-update-farmer/:farmerId' element={<Suspense
                                        fallback={<LoadingView />}><OperatorUpdateFarmer /></Suspense>} />
                                    <Route path='cash-deposit'
                                        element={<Suspense fallback={<LoadingView />}><CashDeposit /></Suspense>} />
                                    <Route path='cash-deposit/list'
                                        element={<Suspense fallback={<LoadingView />}><CahsDepositList /></Suspense>} />
                                </>
                            }
                        </Route>
                        : <Route path="/" element={<Layout />}>
                            <>
                                <Route path="" element={<Suspense fallback={<LoadingView />}><Dashboard /></Suspense>} />
                                <Route path="my-account"
                                    element={<Suspense fallback={<LoadingView />}><MyAccount /></Suspense>} />
                                <Route path="my-trainings"
                                    element={<Suspense fallback={<LoadingView />}><MyTrainings /></Suspense>} />

                                {currentUser.role?.name != 'management' &&
                                    <>
                                        <Route path="transactions/bank" element={<Suspense
                                            fallback={<LoadingView />}><Transactions /></Suspense>} />
                                        <Route path="transactions/all" element={<Suspense
                                            fallback={<LoadingView />}><AllTransactionsReport /></Suspense>} />
                                        <Route path="transactions/cash" element={<Suspense
                                            fallback={<LoadingView />}><CashTransactionsReport /></Suspense>} />

                                        {(superAdmin || admin || rm || rtl || currentUser.role?.name == 'hr' || currentUser?.is_supervisor_head == '1') &&
                                            <>
                                                <Route path="attendance" element={<Suspense
                                                    fallback={<LoadingView />}><UserAttendance /></Suspense>} />
                                                <Route path="attendance/:userId?/:month?" element={<Suspense
                                                    fallback={<LoadingView />}><SingleUserAttendance /></Suspense>} />
                                                <Route path="my-associates" element={<Suspense
                                                    fallback={<LoadingView />}><MyAssociates /></Suspense>} />
                                            </>
                                        }
                                        {!(currentUser.role?.name == 'hr' || currentUser.role?.name == 'accounts')
                                            ? <>
                                                {(superAdmin || admin) && <Route path='users' element={<Suspense
                                                    fallback={<LoadingView />}><Users /></Suspense>} />}

                                                {currentUser?.provider_id == '1' &&
                                                    <>
                                                        {(currentUser.role?.name != 'cso' && currentUser.role?.name != 'client') &&
                                                            <Route path="farmers">
                                                                <Route path="" element={<Suspense
                                                                    fallback={<LoadingView />}><Farmers /></Suspense>} />
                                                                <Route path=":farmerId" element={<Suspense fallback={
                                                                    <LoadingView />}><ViewFarmer /></Suspense>} />
                                                            </Route>
                                                        }
                                                        <Route path="farmers/create" element={<Suspense
                                                            fallback={<LoadingView />}><CreateFarmer /></Suspense>} />
                                                    </>
                                                }

                                                <Route path="services">
                                                    <Route path="" element={<Suspense
                                                        fallback={<LoadingView />}><ServiceList /></Suspense>} />
                                                    <Route path=":serviceId"
                                                        element={<Suspense fallback={<LoadingView />}><UpdateService /></Suspense>} />
                                                    {currentUser?.provider_id == '1' &&
                                                        <Route path="create" element={<Suspense
                                                            fallback={<LoadingView />}><CreateService /></Suspense>} />
                                                    }
                                                </Route>


                                                {((currentUser.role?.name == 'rm') && currentUser?.provider_id != '1') &&
                                                    <Route path="reports">
                                                        <Route path="operator-report" element={<Suspense
                                                            fallback={<LoadingView />}><OperatorReport /></Suspense>} />
                                                        <Route path="service-report" element={<Suspense
                                                            fallback={<LoadingView />}><ServiceReport /></Suspense>} />
                                                        <Route path="asset-report" element={<Suspense
                                                            fallback={<LoadingView />}><AssetReport /></Suspense>} />
                                                        <Route path="battery-report" element={<Suspense
                                                            fallback={<LoadingView />}><BatteryReport /></Suspense>} />
                                                        <Route path="vehicle-report" element={<Suspense
                                                            fallback={<LoadingView />}><VehicleReport /></Suspense>} />
                                                    </Route>
                                                }


                                                {(superAdmin || admin || rm || rtl) &&
                                                    <>
                                                        <Route path="fleet-management">
                                                            <Route path="operators">
                                                                <Route path="" element={<Suspense
                                                                    fallback={<LoadingView />}><Operators /></Suspense>} />
                                                                <Route path="create" element={<Suspense fallback={
                                                                    <LoadingView />}><CreateOperator /></Suspense>} />
                                                                <Route path=":operatorId" element={<Suspense fallback={
                                                                    <LoadingView />}><SingleOperator /></Suspense>} />
                                                            </Route>
                                                            <Route path="dge">
                                                                <Route path="" element={<Suspense
                                                                    fallback={<LoadingView />}><Dges /></Suspense>} />
                                                                <Route path="create" element={<Suspense
                                                                    fallback={<LoadingView />}><CreateDge /></Suspense>} />
                                                                <Route path=":dgeId" element={<Suspense
                                                                    fallback={<LoadingView />}><UpdateDge /></Suspense>} />
                                                            </Route>
                                                            <Route path="assets" element={<Suspense
                                                                fallback={<LoadingView />}><Assets /></Suspense>}>
                                                                <Route path=":assetId" element={<Suspense fallback={
                                                                    <LoadingView />}><DroneDetails /></Suspense>} />
                                                            </Route>
                                                            <Route path="batteries" element={<Suspense
                                                                fallback={<LoadingView />}><Batteries /></Suspense>} />

                                                            <Route path="vehicles" element={<Suspense
                                                                fallback={<LoadingView />}><Vehicles /></Suspense>} />

                                                        </Route>

                                                        {currentUser?.provider_id == '1' &&
                                                            <>
                                                                {(!rtl || !rm) &&
                                                                    <>
                                                                        <Route path='providers' element={<Suspense
                                                                            fallback={
                                                                                <LoadingView />}><Providers /></Suspense>} />
                                                                        <Route path='clusters' element={<Suspense
                                                                            fallback={
                                                                                <LoadingView />}><Cluster /></Suspense>} />
                                                                    </>
                                                                }
                                                                <Route path="crop-management" element={<Suspense
                                                                    fallback={
                                                                        <LoadingView />}><CropManagement /></Suspense>} />
                                                                <Route path="clients">
                                                                    <Route path="regional"
                                                                        element={<Suspense fallback={<LoadingView />}><Clients /></Suspense>} />
                                                                    <Route path="base">
                                                                        <Route path="" element={<Suspense
                                                                            fallback={<LoadingView />}><BaseClientList /></Suspense>} />
                                                                        <Route path="create" element={<Suspense
                                                                            fallback={
                                                                                <LoadingView />}><CreateClient /></Suspense>} />
                                                                        <Route path=":clientId" element={<Suspense
                                                                            fallback={
                                                                                <LoadingView />}><UpdateClient /></Suspense>} />
                                                                    </Route>
                                                                </Route>
                                                                <Route path="schemes">
                                                                    <Route path=""
                                                                        element={<Suspense fallback={<LoadingView />}><Schemes /></Suspense>} />
                                                                    <Route path="create"
                                                                        element={<Suspense fallback={<LoadingView />}><CreateScheme /></Suspense>} />
                                                                </Route>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                            : <>
                                                <Route path="transactions/bank" element={<Suspense
                                                    fallback={<LoadingView />}><Transactions /></Suspense>} />
                                                <Route path="transactions/all" element={<Suspense
                                                    fallback={<LoadingView />}><AllTransactionsReport /></Suspense>} />

                                                <Route path="reports">
                                                    <Route path="service-report"
                                                        element={<Suspense fallback={<LoadingView />}><ServiceReport /></Suspense>} />
                                                </Route>
                                                {(currentUser.role?.name == 'hr') &&
                                                    <>
                                                        <Route path="ter-list" element={<Suspense fallback={<LoadingView />}><TerList /></Suspense>} />
                                                        <Route path="user-trainings-report" element={<Suspense fallback={<LoadingView />}><UserTrainingsReport /></Suspense>} />
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                                {(((currentUser.role?.name != 'accounts' && currentUser.role?.name != 'cso') && currentUser?.provider_id == '1')) &&
                                    <>
                                        <Route path="reports">
                                            <Route path="operator-report" element={<Suspense
                                                fallback={<LoadingView />}><OperatorReport /></Suspense>} />
                                            {(currentUser.role?.name == 'client') &&
                                                <Route path="client-service-report" element={<Suspense
                                                    fallback={<LoadingView />}><ClientServiceReport /></Suspense>} />
                                            }
                                            {(currentUser.role?.name != 'hr') &&
                                                <>
                                                    <Route path="service-report"
                                                        element={<Suspense fallback={<LoadingView />}><ServiceReport /></Suspense>} />
                                                    <Route path="declined-service-report" element={<Suspense
                                                        fallback={<LoadingView />}><DeclinedServices /></Suspense>} />
                                                    <Route path="farmer-report"
                                                        element={<Suspense fallback={<LoadingView />}><FarmerReport /></Suspense>} />
                                                    <Route path="farm-report" element={<Suspense
                                                        fallback={<LoadingView />}><FarmReport /></Suspense>} />
                                                    <Route path="asset-report" element={<Suspense
                                                        fallback={<LoadingView />}><AssetReport /></Suspense>} />
                                                    <Route path="crop-report" element={<Suspense
                                                        fallback={<LoadingView />}><CropReport /></Suspense>} />
                                                    <Route path="battery-report"
                                                        element={<Suspense fallback={<LoadingView />}><BatteryReport /></Suspense>} />
                                                    <Route path="vehicle-report"
                                                        element={<Suspense fallback={<LoadingView />}><VehicleReport /></Suspense>} />
                                                    <Route path="regional-clients-report" element={<Suspense
                                                        fallback={<LoadingView />}><RegionalClientsReport /></Suspense>} />
                                                    <Route path="scheme-report"
                                                        element={<Suspense fallback={<LoadingView />}><SchemeReport /></Suspense>} />
                                                    <Route path="asset-spray-report" element={<Suspense
                                                        fallback={<LoadingView />}><AssetSprayReport /></Suspense>} />
                                                </>
                                            }
                                        </Route>
                                        {(currentUser.role?.name == 'management' || currentUser.role?.name == 'mis') &&
                                            <Route path='clusters'
                                                element={<Suspense fallback={<LoadingView />}><Cluster /></Suspense>} />
                                        }
                                    </>
                                }
                                {(
                                    (superAdmin
                                        || currentUser.role?.name == 'admin'
                                        || currentUser.role?.name == 'management'
                                        || currentUser.role?.name == 'mis'
                                        || currentUser.role?.name == 'rm'
                                        || currentUser.role?.name == 'hr'
                                        || currentUser.role?.name == 'rtl'
                                    )
                                ) &&
                                    <Route path="reports">
                                        <Route path="meeting-report" element={<Suspense fallback={<LoadingView />}><MeetingReport /></Suspense>} />
                                        {(superAdmin || currentUser?.role?.name == 'admin' || currentUser?.role?.name == 'hr') &&
                                            <Route path="user-trainings-report" element={<Suspense fallback={<LoadingView />}><UserTrainingsReport /></Suspense>} />
                                        }
                                    </Route>
                                }

                                {/* <Route path="visited-farmers-report" element={<Suspense fallback={<LoadingView />}><VisitedFramersReport />} /></Suspense> */}

                                {(
                                    superAdmin ||
                                    admin ||
                                    currentUser.role?.name == 'mis' ||
                                    currentUser.role?.name == 'accounts' ||
                                    currentUser.role?.name == 'rm' ||
                                    currentUser.role?.name == 'rtl' ||
                                    currentUser.role?.name == 'cso'
                                ) &&
                                    <>
                                        <Route path="cash-deposit-entries" element={<Suspense fallback={<LoadingView />}><CashDepositEntries /></Suspense>} />
                                        <Route path='cash-deposit-list' element={<Suspense
                                            fallback={<LoadingView />}><CahsDepositList /></Suspense>} />
                                    </>
                                }
                                {(superAdmin || admin) &&
                                    <>
                                        <Route path="trainings"
                                            element={<Suspense fallback={<LoadingView />}><Trainings /></Suspense>}>
                                            <Route path="action"
                                                element={<Suspense
                                                    fallback={<LoadingView />}><CreateTraining /></Suspense>} />
                                        </Route>
                                    </>
                                }

                                {
                                    currentUser.role?.name == 'rtl' &&
                                    <>

                                        <Route path='activity/meeting'
                                            element={<Suspense fallback={<LoadingView />}><Meeting /></Suspense>} />
                                        <Route path='activity/meeting/list'
                                            element={<Suspense fallback={<LoadingView />}><Meetings /></Suspense>} />
                                        <Route path='activity/meeting/create' element={<Suspense
                                            fallback={<LoadingView />}><CreateMeeting /></Suspense>} />
                                    </>
                                }
                            </>
                        </Route>
                    }
                </Route>

                <Route path='coming-soon' element={<Suspense fallback={<LoadingView />}><ComingSoon /></Suspense>} />

                <Route path="farmer-registration"
                    element={<Suspense fallback={<LoadingView />}><FarmerRegistration /></Suspense>} />
                <Route path="ts/:serviceId?" element={<Suspense fallback={<LoadingView />}><OrderTracking /></Suspense>} />
            </Routes>

            <ThemeSnackbar message={errorMessage} />
            <Toaster />

        </ThemeProvider>
    )
}
