import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunkMiddleware from "redux-thunk";

import colorModeSlice from "../slices/color-mode-slice";
import drawerStateSlice from "../slices/drawer-state-slice";
import snackbarMessageSlice from "../slices/snackbar-message-slice";
import userAuthSlice from "../slices/auth-slice";
import pageTitleSlice from "../slices/page-title-slice";
import processStateSlice from "../slices/process-slice";
import operatorDetailSlice from "../slices/operator-detail-slice";
import dgeDetailSlice from "../slices/dge-detail-slice";
import terDetailSlice from "../slices/ter-detail-slice";
import clusterDetailSlice from "../slices/cluster-detail-slice";
import attendanceSlice from "../slices/attendance-slice";
import cashDepositsSlice from "../slices/cash-deposits-slice";
import assetsSlice from "../slices/assets-slice";
import trainingsSlice from "../slices/trainings-slice";
import userTrainingsSlice from "../slices/user-trainings-slice";
import trainingsDataSlice from "../slices/trainings-data-slice";

const reducers = combineReducers({
    drawerState: drawerStateSlice,
    colorMode: colorModeSlice,
    snackbarMessage: snackbarMessageSlice,
    userAuth: userAuthSlice,
    operatorDetails: operatorDetailSlice,
    pageTitle: pageTitleSlice,
    processState: processStateSlice,
    // operatorAttendance: operatorAttendanceSlice,
    terDetail: terDetailSlice,
    clusterDetails: clusterDetailSlice,
    dgeDetails: dgeDetailSlice,

    attendance: attendanceSlice,
    cashDeposits: cashDepositsSlice,
    assets: assetsSlice,
    trainingsData: trainingsDataSlice,

});

const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: ["snackbarMessageReducer"],
    middleware: [thunkMiddleware],
    whitelist: ["drawerState", "colorMode", "userAuth", "attendance"], // reducers to be persisted
    // whitelist: ["drawerState", "colorMode", "userAuth", "operatorAttendance", "attendance"], // reducers to be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: [thunkMiddleware],
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
