import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { EmployeeTraining, Training } from "../models/training"


export interface TrainingsDataInterface {
    all?: Training[]
    user?: EmployeeTraining[]
    selected?: Training | EmployeeTraining
}

const initialState: TrainingsDataInterface = {
    all: [],
    user: [],
    selected: {} as Training | EmployeeTraining
}

export const trainingsDataSlice = createSlice({
    name: "trainingsData",
    initialState,
    // reducers: {
    //     updateTrainingsData: (
    //         state,
    //         action: PayloadAction<TrainingsDataInterface>
    //     ) => {
    //         state.all = action.payload.all
    //         state.user = action.payload.user
    //         state.selected = action.payload.selected
    //     },
    // },
    reducers: {
        updateTrainingsData: (state, action: PayloadAction<Partial<TrainingsDataInterface>>) => ({
            ...state,
            ...action.payload,
        }),
    },
})

export const { updateTrainingsData } = trainingsDataSlice.actions

export default trainingsDataSlice.reducer